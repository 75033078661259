// Body resets
//
// Update the foundational and global aspects of the page.

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-family: $root-font-family;
  font-size: $root-font-size;
  line-height: $root-line-height;

  @media (min-width: $large-breakpoint) {
    font-size: $large-font-size;
  }
}

body {
  color: #fff;
  background-color: #000;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

// No `:visited` state is required by default (browsers will use `a`)
a {
  color: $link-color;
  text-decoration: none;

  // `:focus` is linked to `:hover` for basic accessibility
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  strong {
    color: inherit;
  }
}

img {
  display: block;
  max-width: 100%;
  margin: 0 0 1rem;
  border-radius: 5px;
}

table {
  margin-bottom: 1rem;
  width: 100%;
  font-size: 85%;
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
}

td,
th {
  padding: .25rem .5rem;
  border: 1px solid #e5e5e5;
}

th {
  text-align: left;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}
